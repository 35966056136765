// cancelOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";

/**
 * Call cancelOffer method for investor to cancel the offer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function cancelOffer(address, contract_address, contract_abi, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        const offerDetails = assetData?.offerDetails;
        const listingId = offerDetails?.listing_id;
        const blockchainOfferId = offerDetails?.blockchainOfferId;

        let cancelOfferArgs = {};
        if (assetData?.offerDetails?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            cancelOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6803,
                functionName: "cancelOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        } else if (assetData?.offerDetails?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            cancelOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6803,
                functionName: "cancelOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        } else if (assetData?.offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT || assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
            cancelOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6803,
                functionName: "cancelOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1, // debtTypeId
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        } else if (assetData?.offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
            cancelOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6803,
                functionName: "cancelOffer",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        } else if (assetData?.offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS && (assetData?.offerDetails?.asset_data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS)) {
            cancelOfferArgs = {
                address: contract_address,
                abi: contract_abi,
                account: address,
                chainId: 6803,
                functionName: "cancelInvestment",
                args: [
                    assetData?.offerDetails?.listing_id, // listing id / ipfs code id Integer
                    assetData?.offerDetails?.blockchainOfferId  // offer id Integer
                ]
            }
        }

        // Cancel offer by investor
        const { request: cancelOffer } = await prepareWriteContract(cancelOfferArgs);
        const { hash } = await writeContract(cancelOffer);

        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(2);
                setTimeout(() => {
                    handleSuccess(assetData);
                    handleModalClose();
                    handleActiveStep(-1);
                }, 1000);
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
            }
        }
    } catch (error) {
        console.error('Error calling cancel offer:', error);
        throw error; // propagate the error
    }
}

export { cancelOffer };
