// getRepaymentConfigDetail.js

import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet balance
 * @param {String} contract_address contract_address
 * @param {Object} contract_abi contract_abi
 * @param {Number} listing_id asset listing id
 * @returns user wallet balance
 */
async function getRepaymentConfigDetail(contract_address, contract_abi, listing_id) {
    try {

        const repaymentConfigDetail = await readContract({
            address: contract_address,
            abi: contract_abi,
            functionName: "repaymentConfigDetail",
            chainId: 6803,
            args: [
                Number(listing_id) // listing id
            ]
        });
        return repaymentConfigDetail;
    } catch (error) {
        console.error('Error in fetching repayment config:', error);
        throw error; // propagate the error
    }
}

export { getRepaymentConfigDetail };
