// payMinimumPrepayment.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call repayment method to repay the emi
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function payMinimumPrepayment(address, contract_address, contract_abi, token_address, assetData, user_id, asset_id, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2)

        let args = [
            assetData?.listing_id, // listing id 
            token_address,// _feeToken
            getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.amount), //  // offer amount / offeredAmount
            getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.penaltyMinAmount), //  penaltyAmount
        ]
        if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            let debtTypeId = assetData?.min_prepayment_amount_array?.map((item) => item?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1);
            let token_address_array = assetData?.min_prepayment_amount_array?.map(() => token_address);
            let amount = assetData?.min_prepayment_amount_array?.map((item) => getCurrencyTypeDecimal(assetData?.feeTokenType, item?.amount));
            let penaltyMinAmount = assetData?.min_prepayment_amount_array?.map((item) => getCurrencyTypeDecimal(assetData?.feeTokenType, item?.penaltyMinAmount));
            args = [
                assetData?.listing_id, // listing id
                debtTypeId,
                token_address_array,// _feeToken
                amount, //  // offer amount / offeredAmount
                penaltyMinAmount, //  penaltyAmount
            ]
        }
        // make offer by investor
        const { request: payMinimumPrepaymentAmount } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6803,
            functionName: "payMinimumPrepaymentAmount",
            args: args
        })

        const { hash } = await writeContract(payMinimumPrepaymentAmount)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, assetData, assetData?.amount, asset_id, user_id)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling minimum prepayment:', error);
        throw error; // propagate the error
    }
}

export { payMinimumPrepayment };