// acceptRejectOffer.js
import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, OFFER_STATUS } from "../../constants";

/**
 * Generate accept/reject offer payload based on asset data
 * @param {Object} assetData Asset details
 * @param {String} address User wallet address
 * @param {String} contract_address contract address
 * @param {Object} contract_abi  contract abi
 * @returns {Object} Payload for contract function call
 */
function createAcceptRejectPayload(assetData, address, contract_address, contract_abi) {
    if (assetData?.val?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.val?.asset_data?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
        return {
            account: address,
            chainId: 6803,
            functionName: "acceptRejectOffer",
            address: contract_address,
            abi: contract_abi,
            args: [
                assetData?.val?.listing_id,
                assetData?.val?.blockchainOfferId,
                assetData?.tokenImageData ? `https://gateway.pinata.cloud/ipfs/${assetData.tokenImageData}` : '',
                assetData?.offer_status === OFFER_STATUS.ACCEPTED
            ]
        };
    }

    if (assetData?.val?.investment_type_id === INVESTMENT_TYPE_ID.SALE || assetData?.val?.asset_data?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
        return {
            account: address,
            chainId: 6803,
            functionName: "acceptRejectOffer",
            address: contract_address,
            abi: contract_abi,
            args: [
                assetData?.val?.listing_id,
                assetData?.val?.blockchainOfferId,
                assetData?.offer_status === OFFER_STATUS.ACCEPTED
            ]
        };
    }

    if (assetData?.val?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
        if (assetData?.val?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT || assetData?.val?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT) {
            return {
                account: address,
                chainId: 6803,
                functionName: "acceptRejectOffer",
                address: contract_address,
                abi: contract_abi,
                args: [
                    assetData?.val?.listing_id,
                    assetData?.val?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1,
                    assetData?.val?.blockchainOfferId,
                    assetData?.offer_status === OFFER_STATUS.ACCEPTED
                ]
            };
        } else if (assetData?.val?.capital_type_id === CAPITAL_TYPE_ID.EQUITY) {
            return {
                account: address,
                chainId: 6803,
                functionName: "acceptRejectOffer",
                address: contract_address,
                abi: contract_abi,
                args: [
                    assetData?.val?.listing_id,
                    assetData?.val?.blockchainOfferId,
                    assetData?.offer_status === OFFER_STATUS.ACCEPTED
                ]
            };
        }
    }

    if (assetData?.val?.asset_data?.category_id === CATEGORY_TYPE_ID.FUNDS) {
        const functionName = assetData?.val?.payment_method === 'fiat' ? "acceptRejectFiatInvestment" : "acceptRejectInvestment";
        return {
            account: address,
            chainId: 6803,
            functionName: functionName,
            address: contract_address,
            abi: contract_abi,
            args: [
                assetData?.val?.listing_id,
                assetData?.val?.blockchainOfferId,
                assetData?.offer_status === OFFER_STATUS.ACCEPTED
            ]
        };
    }

    return null;
}


/**
 * Executes contract function to accept or reject an offer
 * @param {String} address User wallet address
 * @param {String} contract_address Contract address
 * @param {Object} contract_abi Contract abi
 * @param {Object} assetData Asset details
 * @param {Function} showSnackbar Snackbar to show success/failure
 * @param {Function} handleActiveStep Function to update the active step
 * @param {Function} handleModalClose Function to close the modal
 * @param {Function} handleSuccess Function to handle transaction success
 * @returns Data or error
 */
async function acceptRejectOffer(address, contract_address, contract_abi, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        const acceptRejectPayload = createAcceptRejectPayload(assetData, address, contract_address, contract_abi);
        if (!acceptRejectPayload) throw new Error("Invalid asset data for offer processing");

        const { request: acceptRejectOffer } = await prepareWriteContract(acceptRejectPayload);
        const { hash } = await writeContract(acceptRejectOffer);

        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(2);
                setTimeout(() => {
                    handleSuccess(assetData);
                    handleModalClose();
                    handleActiveStep(-1);
                }, 1000);
            } else {
                showSnackbar("Transaction Failed", 'error');
                handleModalClose();
                handleActiveStep(-1);
            }
        }
    } catch (error) {
        console.error('Error in calling acceptRejectOffer', error);
        throw error;
    }
}

export { acceptRejectOffer };