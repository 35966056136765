import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Close, Done, EditSharp, SwapVert, ViewComfy, Visibility, Search } from '@mui/icons-material';
import { Button, Box, Paper, InputBase, TableRow, TableCell, TableHead, TableContainer, Table, TableBody, Typography, MenuItem, ListItemText, ImageListItem, FormControl, InputLabel, Select, Avatar, Modal, Stack, Divider, RadioGroup, FormControlLabel, Radio, TextField, Grid, } from '@mui/material';
import { NewLogo } from "../../Components/Images/Images";
import { GetApi, postApi } from '../../Api/Api';
import { CAPITAL_TYPE_ID, CATEGORY_TYPE, CATEGORY_TYPE_ID, CONTRACT_NAME, INVESTMENT_TYPE_ID } from '../../constants';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal';
import NumericFormatCustomDollar from '../../Components/Common/NumericFormatCustomDollar';
import { getRepaymentConfigDetail } from '../../Components/SmartContract/getRepaymentConfigDetail';
import { calculatePrepaymentAmount } from '../../Components/SmartContract/calculatePrepaymentAmount';
import { calculateMinimumPrepaymentAmount } from '../../Components/SmartContract/calculateMinimumPrepaymentAmount';
import './Transactions.css'
import { formatNumber } from '../../Components/Common/USFormat';
import { GetContractAddress } from '../../Components/Common/GetContractAddress';
import { calculateRemainingAmount } from '../../Components/SmartContract/calculateRemainingAmount';
import CommonBackdropLoader from '../../Components/Common/CommonBackdropLoader';
import PayExitTransactionProgressModal from '../../Components/SmartContract/PayExitTransactionProgressModal';
import moment from 'moment/moment';

const sortByOptionsOffers = ['Project Name', 'Amount'];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
}));

const DUE_STATUS = {
    FUTURE: 'FUTURE',
    NEAR: 'NEAR',
    PAST: 'PAST',
    PAID: 'PAID'
}

const LoansPanel = ({ setValue, setLoading }) => {
    const [loansData, setLoansData] = useState([]);
    const [calculateEmiAmount, setCalculateEmiAmount] = useState('');
    const [loanTableData, setLoanTableData] = useState([])
    const [openMakePayment, setOpenMakePayment] = useState(false)
    const [makePaymentData, setMakePaymentData] = useState('')
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('Default');
    const [categoryData, setCategoryData] = useState(); //category data
    const [filterCategoryId, setFilterCategoryId] = useState(0);

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    useEffect(() => {
        /**
        * Api to get Category Type Data
        */
        const getCategoryData = async () => {
            try {
                const res = await GetApi("/proposer/getCategory/");
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData();

        /**
        * Api to get current EMI data
        */
        const getCalculateEmiLoanTable = async () => {
            try {
                setLoading(true)
                const res = await postApi("proposer/getCalculateEmiAmount", { proposer_id: User?.id });
                if (res?.data?.data) {
                    setCalculateEmiAmount(res?.data?.data);
                }
                setLoading(false)
            } catch (error) {
                console.log(error);
                setLoading(false)
            }
        }
        setTimeout(() => {
            getCalculateEmiLoanTable();
        }, 100);
    }, [])

    // set the table when emi data is there
    useEffect(() => {
        if (calculateEmiAmount && calculateEmiAmount.length > 0) {
            let table = [];
            calculateEmiAmount.forEach((loan) => {
                // if (loan?.data) {
                let emiData = loan?.data ? loan?.data : {}
                if (loan?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && emiData?.length) {

                    let totalLoanAmt = 0, totalAmount = 0, interestAmt = 0, principalAmt = 0;
                    let emi_amount_array = [];
                    emiData?.forEach((item) => {
                        totalLoanAmt += item?.totalLoanAmt
                        totalAmount += parseFloat(Math.ceil(item?.totalAmount * 100) / 100)
                        interestAmt += parseFloat(Math.ceil(item?.interestAmt * 100) / 100)
                        principalAmt += parseFloat(Math.ceil(item?.principalAmt * 100) / 100)
                        emi_amount_array.push({ capital_type_id: item?.capital_type_id, emi_amount: parseFloat(Math.ceil(item?.totalAmount * 100) / 100).toFixed(2) })
                    })
                    //create a single table row i.e an loan emiData
                    let tableRow = {
                        project_name: loan?.asset_title,
                        listing_id: loan?.listingId,
                        category_name: loan?.category_name,
                        category_id: loan?.category_id,
                        total_amount: totalLoanAmt > 0 ? totalLoanAmt : 0,
                        emi_amount: totalAmount > 0 ? parseFloat(Math.ceil(totalAmount * 100) / 100).toFixed(2) : 0,
                        emi_amount_array: emi_amount_array,
                        monthly_interest: interestAmt > 0 ? parseFloat(interestAmt).toFixed(2) : 0,
                        monthly_principal: principalAmt > 0 ? parseFloat(principalAmt).toFixed(2) : 0,
                        user_id: User.id,
                        asset_id: loan?.asset_id,
                        contract_version: loan?.contract_version,
                        payment_date: loan?.payment_date,
                        data: loan?.response,
                        data1: loan?.response1,
                        capital_type_id: loan?.capital_type_id,
                        capital_info: loan?.capital_info?.map((capital) => { return { ...capital, capital_type_id: capital?.capital_type?.id } }),

                    };
                    tableRow.investment_type_id = INVESTMENT_TYPE_ID.FRACTION;
                    table.push(tableRow);
                } else if (loan?.category_id === CATEGORY_TYPE_ID.WATCH || loan?.category_id === CATEGORY_TYPE_ID.GOLD || loan?.category_id === CATEGORY_TYPE_ID.ASSET) {
                    //create a single table row i.e an loan emiData
                    let tableRow = {
                        project_name: loan?.asset_title,
                        listing_id: loan?.listingId,
                        category_name: loan?.category_name,
                        category_id: loan?.category_id,
                        total_amount: emiData?.totalLoanAmt > 0 ? emiData?.totalLoanAmt : 0,
                        emi_amount: emiData?.totalAmount > 0 ? parseFloat(Math.ceil(emiData?.totalAmount * 100) / 100).toFixed(2) : 0,
                        monthly_interest: emiData?.interestAmt > 0 ? parseFloat(emiData?.interestAmt).toFixed(2) : 0,
                        monthly_principal: emiData?.principalAmt > 0 ? parseFloat(emiData?.principalAmt).toFixed(2) : 0,
                        user_id: User.id,
                        asset_id: loan?.asset_id,
                        payment_date: loan?.payment_date,
                        contract_version: loan?.contract_version,
                        data: loan?.response,
                        capital_type_id: loan?.capital_type_id
                    };
                    tableRow.investment_type_id = INVESTMENT_TYPE_ID.LOAN;
                    table.push(tableRow);
                }
                // }
            });
            setLoanTableData(table);
            setLoansData(table);
        } else {
            setLoanTableData([]);
        }
    }, [calculateEmiAmount])

    /**
     * searches in the data [title]
     * @param {string} toSearch 
     * @returns {void}
     */
    const searchInData = (toSearch) => {
        setSearchString(toSearch)
        if (toSearch.length > 2) {
            if (filterCategoryId > 0) {
                setLoanTableData(loansData.filter(o => o.project_name.toLowerCase().includes(toSearch.toLowerCase()) && o.category_id === filterCategoryId))
            } else {
                setLoanTableData(loansData.filter(o => o.project_name.toLowerCase().includes(toSearch.toLowerCase())))
            }
        } else if (toSearch.length <= 2) {
            if (filterCategoryId > 0) {
                // getLoans({ user_id: User.id, category_id: filterCategoryId });
                setLoanTableData(loansData.filter(o => o.category_id === filterCategoryId))
            } else {
                // getLoans({ user_id: User.id });
                setLoanTableData(loansData)
            }
        }
    }

    // Getting filter data on category id change
    useEffect(() => {
        if (filterCategoryId > 0) {
            // getLoans({ user_id: User.id, category_id: filterCategoryId });
            setLoanTableData(loansData.filter(o => o.category_id === filterCategoryId))
        } else {
            // getLoans({ user_id: User.id });
            setLoanTableData(loansData)
        }
    }, [filterCategoryId])

    /**
     * sorting data by amount and project name
     * @param {event} event 
     */
    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
        let value = event.target.value;
        if (value === 'Project Name') {
            setLoanTableData([...loanTableData].sort((a, b) => a.project_name.localeCompare(b.project_name)))
        }
        else if (value === 'Amount') {
            setLoanTableData([...loanTableData].sort((a, b) => parseFloat(a.total_amount) - parseFloat(b.total_amount)))
        }
        else {
            setLoanTableData(loanTableData)
        }
    }

    /**
     * opens the payment modal for making payment
     * @param {Object}  
     */
    const handleOpenMakePayment = (row) => {
        setMakePaymentData(row);
        setOpenMakePayment(true)
    }

    /**
     * closes the payment review modal
     */
    const handleCloseMakePayment = () => {
        setOpenMakePayment(false)
    }

    /**
    * Handle change in category filter
    * @param {Event} event 
    */
    const handleChangeCategory = (event) => {
        setFilterCategoryId(event.target.value)
    };

    return (
        <>

            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            Loan Received
                        </Typography>
                    </Box>
                </Box>

                <Box className="fp-right">
                    <Box className="filters-group-wrap">
                        <Box className="filters-group-wrap">
                            {/* Search bar filter */}
                            <Box className="filterSearch-form">
                                <Box component={"span"} className="search-icon">
                                    <Search />
                                </Box>
                                <InputBase
                                    // ref={searchBarRef}
                                    className="filterSearch"
                                    placeholder={"Search in loans"}
                                    value={searchString}
                                    onChange={(e) => searchInData(e.target.value)}
                                    inputProps={{ "aria-label": "search in transactions" }}
                                />
                            </Box>
                        </Box>

                        {/* Category filter */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Category
                                    <ViewComfy />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    onChange={handleChangeCategory}
                                    value={filterCategoryId}
                                    label="Category"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {
                                        categoryData?.length && categoryData?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                                                    <Typography className="menu-icon">
                                                        <ImageListItem>
                                                            <img
                                                                src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                alt={value.category_icon?.original_name}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })

                                    }
                                </Select>
                            </FormControl>
                        </Box>


                        {/* Sorting  */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Sort By
                                    <SwapVert />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    value={sortBy}
                                    onChange={handleSortByChange}
                                    label="Sort By"
                                    variant='outlined'
                                >
                                    <MenuItem value={'Default'}>Default</MenuItem>
                                    {
                                        sortByOptionsOffers?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value}>
                                                    <ListItemText>{value}</ListItemText>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </Box>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell width={'22%'}>Project Name</StyledTableCell>
                            <StyledTableCell align="left">Asset Type</StyledTableCell>
                            <StyledTableCell align="left">Total Loan Amount</StyledTableCell>
                            <StyledTableCell align="left">Total EMI Amount</StyledTableCell>
                            <StyledTableCell align="left">Principal</StyledTableCell>
                            <StyledTableCell align="left">Interest</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanTableData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.name}${i}`}>
                                <StyledTableCell component="th" scope="row"><Link to={`/project-details/${row.asset_id}`} >{row?.project_name}</Link></StyledTableCell>
                                <StyledTableCell align="left">{row?.category_name}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.total_amount))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.emi_amount))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.monthly_principal))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.monthly_interest))}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {
                                        row?.dueStatus === DUE_STATUS.PAID ?
                                            <Button onClick={() => { }} startIcon={<Visibility />} className='status-button'>View Transaction</Button>
                                            :
                                            <Button
                                                // disabled={true}
                                                onClick={() => handleOpenMakePayment(row)}
                                                disableFocusRipple
                                                disableRipple
                                                startIcon={<EditSharp />} className='status-button'>
                                                Make Payment
                                            </Button>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
            {openMakePayment
                ? <MakePayment payEMIData={makePaymentData} openMakePayment={openMakePayment} handleCloseMakePayment={handleCloseMakePayment} setValue={setValue} />
                : null
            }
        </>
    )
}

export default LoansPanel;

const tableHeaderStyles = {
    fontWeight: 'bold',
    // Additional styles for the header
};

function MakePayment({ payEMIData, openMakePayment, handleCloseMakePayment, setValue }) {
    const style = { position: "relative", boxShadow: 24, borderRadius: "24px", width: "1043px", };
    const rowTextStyles = { color: "burlywood", fontWeight: '500', fontSize: '16px' }

    const [transactionModal, setTransactionModal] = useState({
        open: false,
        title: "Repayment",
        message: `is done successfully, For transaction see ` + <span style={{ textDecoration: 'underline' }}><Link to="/user/transactions" target="_blank" >My Transactions</Link></span> + ` page.`,
    })
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [selectedOption, setSelectedOption] = useState('Pay EMI');
    const [selectedCurrency, setCurrencyOption] = useState('USDT');
    const [openPayEMI, setOpenPayEMI] = useState(false);
    const [openPrepayment, setOpenPrepayment] = useState(false);
    const [openMinimumPrepayment, setOpenMinimumPrepayment] = useState(false);
    const [propData, setPropData] = useState();
    const [prepaymentData, setPrepaymentData] = useState('');
    const [miniPrepaymentData, setMiniPrepaymentData] = useState('');
    const [payableAmount, setPayableAmount] = useState(payEMIData?.emi_amount > 0 ? parseFloat(Math.ceil(payEMIData?.emi_amount * 100) / 100).toFixed(2) : 0);
    const [minimumPrepaymentAmt, setMinimumPrepaymentAmt] = useState(0);
    const [penaltyMinAmount, setPenaltyMinAmount] = useState(0);
    const [penaltyAmount, setPenaltyAmount] = useState(0);
    const [isMinimumPrepayment, setIsMinimumPrepayment] = useState();
    const [remainingPrincipalAmount, setRemainingPrincipalAmount] = useState(0);
    const [isCalculated, setIsCalculated] = useState(false);
    const [calculatedAmount, setCalculatedAmount] = useState(0);
    const [emiData, setEmiData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [prepaymentAmountArray, setPrepaymentAmountArray] = useState();
    const [minPrepaymentAmountArray1, setMinPrepaymentAmountArray1] = useState([]);
    const [minPrepaymentAmountArray2, setMinPrepaymentAmountArray2] = useState([]);

    // for senior
    const [minimumPrepaymentAmtS, setMinimumPrepaymentAmtS] = useState(0);
    const [penaltyMinAmountS, setPenaltyMinAmountS] = useState(0);
    const [remainingPrincipalAmountS, setRemainingPrincipalAmountS] = useState(0);
    const [isCalculatedS, setIsCalculatedS] = useState(false);
    const [calculatedAmountS, setCalculatedAmountS] = useState(0);
    const [payableAmountS, setPayableAmountS] = useState(0);
    const [miniPrepaymentDataS, setMiniPrepaymentDataS] = useState('');
    const [payDividendAmt, setDividendAmt] = useState(0);
    const [openPayExitDividend, setOpenPayExitDividend] = useState(false);

    /**
     * Handle change in payment type
     * @param {Event} event 
     */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value) };

    /**
     * Handle change in payment type
     * @param {Event} event 
     */
    const handleCurrencyChange = (event) => { setCurrencyOption(event.target.value) };

    // Handle change in minimum Prepayment Amount
    useEffect(() => {
        if (selectedOption === "Pay Prepayment") {
            if (!prepaymentData) {
                getPrepaymentData()
            } else {
                if (prepaymentData) {
                    // let penalty = 0;
                    // setPayableAmount(parseFloat(Math.ceil((Number(prepaymentData?.totalAmount) / 1e18) * 100) / 100).toFixed(2))
                    // prepaymentData?.penaltyAmount?.forEach((item) => {
                    //     penalty += Number(item) / 1e18
                    // })
                    // setPenaltyAmount(parseFloat(penalty).toFixed(2));
                    let penalty = 0;
                    if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                        let totalAmount = 0;
                        let prepayment_amount = []
                        prepaymentData?.forEach((data) => {
                            let tokenAmount = 0;
                            data?.tokenAmount?.forEach((item) => {
                                tokenAmount += Math.ceil(Number(item) / 1e16) / 100
                            })
                            totalAmount += tokenAmount;
                            prepayment_amount.push({ capital_type_id: data?.capital_type_id, prepayment_amount: parseFloat(tokenAmount).toFixed(2) })
                        })
                        setPayableAmount(parseFloat(totalAmount).toFixed(2))
                        prepaymentData?.forEach((data) => {
                            data?.penaltyAmount?.forEach((item) => {
                                penalty += Number(item) / 1e18
                            })
                        })
                        setPenaltyAmount(parseFloat(penalty).toFixed(2));
                        setPrepaymentAmountArray(prepayment_amount);
                    }
                    else {
                        setPayableAmount(parseFloat(Math.ceil((Number(prepaymentData?.totalAmount) / 1e18) * 100) / 100).toFixed(2))
                        prepaymentData?.penaltyAmount?.forEach((item) => {
                            penalty += Number(item) / 1e18
                        })
                        setPenaltyAmount(parseFloat(penalty).toFixed(2));
                    }
                }
            }
        } else if (selectedOption === "Pay Minimum Prepayment") {
            if (payEMIData?.asset_id == 4) { // for mainnet asset id === 4
                setRemainingPrincipalAmount(payEMIData?.total_amount)
            } else {
                getCalculateRemainingAmount();
            }
            // if (minimumPrepaymentAmt > 0) {
            // } else {
            setPayableAmount(0);
            setPenaltyMinAmount(0);
            setCalculatedAmount(0);
            setIsCalculated(false)
            // }
        } else {
            setPayableAmount(parseFloat(Math.ceil(payEMIData?.emi_amount * 100) / 100).toFixed(2))
        }
    }, [selectedOption])

    // handle the case when minimum prepayment amount is null or zero then set payable amount to zero
    useEffect(() => {
        if (!minimumPrepaymentAmt && selectedOption === "Pay Minimum Prepayment") {
            setTimeout(() => {
                setPayableAmount(0);
                setPenaltyMinAmount(0);
                getMinimumPrepayment(0, 5);
            }, 1000);
        }
    }, [minimumPrepaymentAmt])

    useEffect(() => {
        if (!minimumPrepaymentAmtS && selectedOption === "Pay Minimum Prepayment") {
            setTimeout(() => {
                setPayableAmountS(0);
                setPenaltyMinAmountS(0);
                getMinimumPrepayment(0, 4);
            }, 1000);
        }
    }, [minimumPrepaymentAmtS])


    // handle prepayment data
    useEffect(() => {
        if (prepaymentData) {
            let penalty = 0;
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                let totalAmount = 0;
                let prepayment_amount = [];
                prepaymentData?.forEach((data) => {
                    let tokenAmount = 0;
                    data?.tokenAmount?.forEach((item) => {
                        tokenAmount += Math.ceil(Number(item) / 1e16) / 100
                    })
                    totalAmount += tokenAmount;
                    prepayment_amount.push({ capital_type_id: data?.capital_type_id, prepayment_amount: parseFloat(tokenAmount).toFixed(2) })
                })
                setPayableAmount(parseFloat(totalAmount).toFixed(2))
                prepaymentData?.forEach((data) => {
                    data?.penaltyAmount?.forEach((item) => {
                        penalty += Number(item) / 1e18
                    })
                })
                setPenaltyAmount(parseFloat(penalty).toFixed(2));
                setPrepaymentAmountArray(prepayment_amount);
            }
            else {
                setPayableAmount(parseFloat(Math.ceil((Number(prepaymentData?.totalAmount) / 1e18) * 100) / 100).toFixed(2))
                prepaymentData?.penaltyAmount?.forEach((item) => {
                    penalty += Number(item) / 1e18
                })
                setPenaltyAmount(parseFloat(penalty).toFixed(2));
            }
        }
    }, [prepaymentData])

    useEffect(() => {
        if (miniPrepaymentData) {
            setPayableAmount(parseFloat(Number(miniPrepaymentData?.totalAmount) / 1e18).toFixed(2));
            let penalty = 0;
            miniPrepaymentData?.penaltyAmount?.forEach((item) => {
                penalty += Number(item) / 1e18
            })
            setPenaltyMinAmount(parseFloat(penalty).toFixed(2))
            setMinPrepaymentAmountArray1([{ capital_type_id: miniPrepaymentData?.capital_type_id, amount: calculatedAmount, min_prepayment_amount: parseFloat(Number(miniPrepaymentData?.totalAmount) / 1e18).toFixed(2), penaltyMinAmount: parseFloat(penalty).toFixed(2) }])
        }
    }, [miniPrepaymentData])

    useEffect(() => {
        if (miniPrepaymentDataS) {
            setPayableAmountS(parseFloat(Number(miniPrepaymentDataS?.totalAmount) / 1e18).toFixed(2));
            let penalty = 0;
            miniPrepaymentDataS?.penaltyAmount?.forEach((item) => {
                penalty += Number(item) / 1e18
            })
            setPenaltyMinAmountS(parseFloat(penalty).toFixed(2))
            setMinPrepaymentAmountArray2([{ capital_type_id: miniPrepaymentDataS?.capital_type_id, amount: calculatedAmountS, min_prepayment_amount: parseFloat(Number(miniPrepaymentDataS?.totalAmount) / 1e18).toFixed(2), penaltyMinAmount: parseFloat(penalty).toFixed(2) }])
        }
    }, [miniPrepaymentDataS])

    /**
     * Handle change in payment type
     * @param {Event} event 
     */
    useEffect(() => {
        if (selectedOption === "Distribute Exit Amount") {
            if (payDividendAmt) {
                setPayableAmount(parseInt(payDividendAmt));
            } else {
                if (!payDividendAmt) {
                    setPayableAmount(0)
                }
            }
        }
    }, [payDividendAmt, selectedOption])

    // get prepayment config from blockchain
    useEffect(() => {
        async function getRepaymentConfig() {
            try {
                setIsLoading(true)
                const response = await GetContractAddress({ ...payEMIData }, payEMIData?.contract_version, payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ? CONTRACT_NAME.SPV_DEBT_REPAYMENT : CONTRACT_NAME.WATCH_REPAYMENT_ADDRESS);
                let contractAddress = null
                let contractAbi = null
                if (response && response?.length) {
                    contractAddress = response[0].address;
                    contractAbi = response[0].abi;
                }
                const config = await getRepaymentConfigDetail(contractAddress, contractAbi, payEMIData?.listing_id, payEMIData?.investment_type_id);
                setIsMinimumPrepayment(config[6])
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
            }
        }
        // call the function
        getRepaymentConfig();
    }, [])

    /**
    * Api to get prepayment data
    */
    const getPrepaymentData = async () => {
        try {
            const response = await GetContractAddress({ ...payEMIData }, payEMIData?.contract_version, payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ? CONTRACT_NAME.SPV_DEBT_REPAYMENT : CONTRACT_NAME.WATCH_REPAYMENT_ADDRESS);
            let contractAddress = null
            let contractAbi = null
            if (response && response?.length) {
                contractAddress = response[0].address;
                contractAbi = response[0].abi;
            }
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                let prepayData = [];
                const debtTypes = [
                    { type: CAPITAL_TYPE_ID.SENIOR_DEBT, key: 'SENIOR_DEBT' },
                    { type: CAPITAL_TYPE_ID.JUNIOR_DEBT, key: 'JUNIOR_DEBT' }
                ];

                // Filter only the applicable debt types
                const applicableDebtTypes = debtTypes.filter(debt => payEMIData?.capital_type_id?.includes(debt.type));

                // Use Promise.all to fetch prepayment data for all applicable debt types concurrently
                const results = await Promise.all(
                    applicableDebtTypes.map(async (debt) => {
                        const prepayment = await calculatePrepaymentAmount(contractAddress, contractAbi, {
                            ...payEMIData,
                            capital_type_id: debt.type
                        });
                        return { ...prepayment, capital_type_id: debt.type };
                    })
                );

                prepayData = results;
                setPrepaymentData(prepayData);
            } else {
                let getPrepayment = await calculatePrepaymentAmount(contractAddress, contractAbi, payEMIData);
                setPrepaymentData(getPrepayment)
            }
            setIsCalculated(true)
        } catch (error) {
            console.log(error);
        }
    }

    /**
    * Api to get prepayment data
    */
    const getMinimumPrepayment = async (amount, capital_type_id) => {
        try {
            const response = await GetContractAddress({ ...payEMIData }, payEMIData?.contract_version, payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ? CONTRACT_NAME.SPV_DEBT_REPAYMENT : CONTRACT_NAME.WATCH_REPAYMENT_ADDRESS);
            let contractAddress = null
            let contractAbi = null
            if (response && response?.length) {
                contractAddress = response[0].address;
                contractAbi = response[0].abi;
            }
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                if (capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT) {
                    let getMinimumPrepaymentData1 = await calculateMinimumPrepaymentAmount(contractAddress, contractAbi, { ...payEMIData, capital_type_id: CAPITAL_TYPE_ID.SENIOR_DEBT }, amount);
                    setCalculatedAmountS(amount)
                    setMiniPrepaymentDataS({ ...getMinimumPrepaymentData1, capital_type_id: CAPITAL_TYPE_ID.SENIOR_DEBT })
                } else if (capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT) {
                    let getMinimumPrepaymentData2 = await calculateMinimumPrepaymentAmount(contractAddress, contractAbi, { ...payEMIData, capital_type_id: CAPITAL_TYPE_ID.JUNIOR_DEBT }, amount);
                    setCalculatedAmount(amount)
                    setMiniPrepaymentData({ ...getMinimumPrepaymentData2, capital_type_id: CAPITAL_TYPE_ID.JUNIOR_DEBT })
                }
            } else {
                let getMinimumPrepaymentData = await calculateMinimumPrepaymentAmount(contractAddress, contractAbi, payEMIData, amount);
                setCalculatedAmount(amount)
                setMiniPrepaymentData(getMinimumPrepaymentData)
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
    * Api to get prepayment data
    */
    const getCalculateRemainingAmount = async () => {
        try {
            const response = await GetContractAddress({ ...payEMIData }, payEMIData?.contract_version, payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ? CONTRACT_NAME.SPV_DEBT_REPAYMENT : CONTRACT_NAME.WATCH_REPAYMENT_ADDRESS);
            let contractAddress = null
            let contractAbi = null
            if (response && response?.length) {
                contractAddress = response[0].address;
                contractAbi = response[0].abi;
            }
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                const debtTypes = [
                    { type: CAPITAL_TYPE_ID.SENIOR_DEBT, key: 'SENIOR_DEBT' },
                    { type: CAPITAL_TYPE_ID.JUNIOR_DEBT, key: 'JUNIOR_DEBT' }
                ];

                // Filter only the applicable debt types
                const applicableDebtTypes = debtTypes.filter(debt => payEMIData?.capital_type_id?.includes(debt.type));

                // Use Promise.all to fetch prepayment data for all applicable debt types concurrently
                const results = await Promise.all(
                    applicableDebtTypes.map(async (debt) => {
                        const prepayment = await calculateRemainingAmount(contractAddress, contractAbi, {
                            ...payEMIData,
                            capital_type_id: debt.type
                        });
                        return { ...prepayment, capital_type_id: debt.type };
                    })
                );
                let senior = results?.find(result => result.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT)
                let junior = results?.find(result => result.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)
                if (senior) {
                    setIsCalculatedS(true)
                    const remainingAmt = senior?.principalAmount?.reduce((sum, item) => sum + parseFloat((Number(item) / 1e18).toFixed(2)), 0);
                    setRemainingPrincipalAmountS((parseFloat(remainingAmt)))
                }
                if (junior) {
                    setIsCalculated(true)
                    const remainingAmt = junior?.principalAmount?.reduce((sum, item) => sum + parseFloat((Number(item) / 1e18).toFixed(2)), 0);
                    setRemainingPrincipalAmount((parseFloat(remainingAmt)))
                }
            } else {
                let remainingAmtData = await calculateRemainingAmount(contractAddress, contractAbi, payEMIData);
                if (remainingAmtData) {
                    setIsCalculated(true)
                    const remainingAmt = remainingAmtData?.principalAmount?.reduce((sum, item) => sum + parseFloat((Number(item) / 1e18).toFixed(2)), 0);
                    setRemainingPrincipalAmount((parseFloat(remainingAmt)))
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * handle pay button
     */
    const handleMakePayment = async () => {
        try {
            if (payEMIData?.category_name === CATEGORY_TYPE.WATCH || payEMIData?.category_name === CATEGORY_TYPE.GOLD || payEMIData?.category_name === CATEGORY_TYPE.ASSET) {
                payEMIData.investment_type_id = INVESTMENT_TYPE_ID.LOAN
            }
            if (selectedOption === "Pay EMI") {
                setOpenPayEMI(true);
                setPropData({ ...payEMIData })
            } else if (selectedOption === "Pay Prepayment") {
                setOpenPrepayment(true);
                setPropData({ ...payEMIData, prepayment_amount: payableAmount, prepayment_amount_array: prepaymentAmountArray })
            } else if (selectedOption === "Pay Minimum Prepayment") {
                if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                    if (minimumPrepaymentAmt || minimumPrepaymentAmtS) {
                        let min_prepayment_amount_array = []
                        if ((parseFloat(minimumPrepaymentAmtS) > 0 && calculatedAmountS > 0 && (parseFloat(minimumPrepaymentAmtS) <= parseFloat(remainingPrincipalAmountS)))) {
                            min_prepayment_amount_array = [...minPrepaymentAmountArray2]
                        }
                        if ((parseFloat(minimumPrepaymentAmt) > 0 && calculatedAmount > 0 && (parseFloat(minimumPrepaymentAmt) <= parseFloat(remainingPrincipalAmount)))) {
                            min_prepayment_amount_array = [...min_prepayment_amount_array, ...minPrepaymentAmountArray1]
                        }
                        if (min_prepayment_amount_array?.length) {
                            setPropData({ ...payEMIData, amount: calculatedAmount, min_prepayment_amount: (parseFloat(payableAmount) + parseFloat(payableAmountS)), penaltyMinAmount: penaltyMinAmount, min_prepayment_amount_array: min_prepayment_amount_array })
                            setOpenMinimumPrepayment(true);
                        }
                    }
                } else {
                    if (minimumPrepaymentAmt) {
                        setOpenMinimumPrepayment(true);
                        setPropData({ ...payEMIData, amount: calculatedAmount, min_prepayment_amount: payableAmount, penaltyMinAmount: penaltyMinAmount })
                    }
                }
            } else if (selectedOption === 'Distribute Exit Amount' && payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                setPropData({ ...payEMIData, amount: payableAmount })
                setOpenPayExitDividend(true)
            }
        } catch (error) {
            showSnackbar(error?.message, 'error')
        }
    }

    /**
    * Function to handle pay emi modal close
    */
    const handleClosePayEMI = () => {
        setOpenPayEMI(false);
    }

    /**
    * Function to handle prepayment modal close
    */
    const handleClosePrepayment = () => {
        setOpenPrepayment(false);
    }
    /**
    * Function to handle modal close
    */
    const handleCloseMinimumPrepayment = () => {
        setOpenMinimumPrepayment(false);
    }
    /**
    * Function to handle modal close
    */
    const handleCloseExit = () => {
        setOpenPayExitDividend(false);
    }
    /**
   * Function call after transaction confirm
   */
    const confirmStakeExitDividend = async () => {
        handleCloseMakePayment();
        if (setValue) {
            setValue(0)
        }
        navigate('/user/transactions')
    };
    /**
    * Function call after transaction confirm
    */
    const confirmStake = async (data, assetData, amount, asset_id, user_id) => {
        handleCloseMakePayment();
        if (selectedOption === "Pay EMI") {
            setTransactionModal({ ...transactionModal, open: true, title: "Loan Installment" })
        } else if (selectedOption === "Pay Prepayment") {
            setTransactionModal({ ...transactionModal, open: true, title: "Prepayment" })
        } else if (selectedOption === "Pay Minimum Prepayment") {
            setTransactionModal({ ...transactionModal, open: true, title: "Minimum Prepayment" })
        }
        if (setValue) {
            setValue(0)
        }
        navigate('/user/transactions');
    };


    useEffect(() => {
        if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (payEMIData?.data || payEMIData?.data1) || payEMIData?.data) {
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                let tableData = []
                let emiAmount = 0, penaltyAmount = 0;
                if (payEMIData?.data) {
                    let parseData = JSON.parse(payEMIData?.data);
                    for (let i = 0; i < parseData?.length; i++) {
                        emiAmount += Math.ceil((parseFloat(parseData[i].principalAmount) / 1e18 + parseFloat(parseData[i]?.interestAmount) / 1e18) * 100) / 100
                        penaltyAmount += Math.ceil((parseFloat(parseData[i]?.penaltyAmount) / 1e18) * 100) / 100
                    }
                }
                if (payEMIData?.data1) {
                    let parseData = JSON.parse(payEMIData?.data1);
                    for (let i = 0; i < parseData?.length; i++) {
                        emiAmount += Math.ceil((parseFloat(parseData[i].principalAmount) / 1e18 + parseFloat(parseData[i]?.interestAmount) / 1e18) * 100) / 100
                        penaltyAmount += Math.ceil((parseFloat(parseData[i]?.penaltyAmount) / 1e18) * 100) / 100
                    }
                }
                tableData.push(
                    { emiAmount: parseFloat(Math.ceil(emiAmount * 100) / 100).toFixed(2), penalty: parseFloat(Math.ceil(penaltyAmount * 100) / 100).toFixed(2), paymentDate: payEMIData?.payment_date }
                )
                setEmiData(tableData)
            } else {
                let parseData = JSON.parse(payEMIData?.data);
                let tableData = []
                let emiAmount = 0, penaltyAmount = 0;
                for (let i = 1; i < parseData?.monthDifference?.length; i++) {
                    emiAmount += (parseFloat(parseData?.principalAmount[i]) / 1e18 + parseFloat(parseData?.interestAmount[i]) / 1e18)
                    penaltyAmount += parseFloat(parseData?.penaltyAmount[i]) / 1e18
                }
                tableData.push(
                    { emiAmount: parseFloat(Math.ceil(emiAmount * 100) / 100).toFixed(2), penalty: parseFloat(Math.ceil(penaltyAmount * 100) / 100).toFixed(2), paymentDate: payEMIData?.payment_date }
                )
                setEmiData(tableData)
            }
        }
    }, [payEMIData])

    /**
     * Function to set the Due Date for EMI
     * @param {*} day 
     * @returns {string} formatted date as "DD/MM/YYYY"
     */
    function adjustPaymentDate(day) {
        // Get the current date
        const currentDate = new Date();

        // Extract today's day, month, and year
        const currentDay = currentDate.getDate();
        const currentMonth = currentDate.getMonth(); // Zero-indexed, 0 = January, 11 = December
        const currentYear = currentDate.getFullYear();

        let paymentDate;

        if (day <= currentDay) {
            // Payment day is today or in the past
            if (currentMonth === 11) {  // If current month is December
                // Move to January next year
                paymentDate = new Date(currentYear + 1, 0, day);
            } else {
                // Move to next month (same year)
                paymentDate = new Date(currentYear, currentMonth + 1, day);
            }
        } else {
            // Payment day is in the future (greater than currentDay)
            paymentDate = new Date(currentYear, currentMonth, day);
        }

        // Format the date as "DD/MM/YYYY"
        return formatDate(paymentDate);
    }

    /**
     * Function to format the date into "DD/MM/YYYY"
     * @param {Date} date 
     * @returns {string} formatted date
     */
    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adjust for zero-indexed month
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }



    return (
        <>
            {/* modal for pay emi, prepayment and minimum prepayment */}
            {openMakePayment ?
                <Modal
                    open={openMakePayment}
                    onClose={handleCloseMakePayment}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Box className="modal-body" sx={style}>
                        <Box className="headContent">
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: "100%", height: 90, objectFit: "contain" }}
                                />
                            </Box>
                        </Box>
                        <Box className="modalContentDone" >
                            <Box className="options-container" my={2} px={4}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="payment-method"
                                        name="payment-method"
                                        defaultValue="Pay EMI"
                                        row
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        <FormControlLabel
                                            value="Pay EMI"
                                            control={<Radio />}
                                            label="Pay EMI"
                                        />
                                        <FormControlLabel
                                            value="Pay Prepayment"
                                            control={<Radio />}
                                            label="Pay Prepayment"
                                        />
                                        {isMinimumPrepayment ?
                                            <FormControlLabel
                                                value="Pay Minimum Prepayment"
                                                control={<Radio />}
                                                label="Pay Minimum Prepayment"
                                            /> : null
                                        }
                                        {payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ?
                                            <FormControlLabel
                                                value="Distribute Exit Amount"
                                                control={<Radio />}
                                                label="Distribute Exit Amount"
                                            /> : null
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box className="main-content loan " >
                                <Box className="votingContent">
                                    {/* <Typography component="h1" mt={0} px={4} className="headText" textAlign={"center"}>{selectedOption}</Typography > */}
                                    {selectedOption === 'Pay Minimum Prepayment' ?
                                        <>
                                            {
                                                payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ?
                                                    <Grid container>
                                                        {payEMIData?.capital_type_id?.includes(CAPITAL_TYPE_ID.SENIOR_DEBT) ?
                                                            <Grid item md={payEMIData?.capital_type_id?.length > 1 ? 6 : 12} sm={12}>
                                                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                                                    <Box className='minimum-prepayment'>
                                                                        <Box>
                                                                            <Typography className='font-16' style={{
                                                                                textAlign: "start",
                                                                                paddingBottom: "4px"
                                                                            }}>
                                                                                Senior Debt
                                                                            </Typography>
                                                                            <TextField
                                                                                className='minimum-prepayment-text'
                                                                                style={{ width: '200px' }}
                                                                                placeholder='Enter Amount'
                                                                                onChange={(e) => setMinimumPrepaymentAmtS(Math.floor(parseFloat(e.target.value) || 0))}
                                                                                InputProps={{
                                                                                    inputComponent: NumericFormatCustomDollar,
                                                                                    style: {
                                                                                        color: 'white'
                                                                                    }
                                                                                }}
                                                                                value={minimumPrepaymentAmtS}
                                                                                onKeyDown={(event) => {
                                                                                    if (event?.key === "-" || event?.key === "+" || event?.key === ".") {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                error={parseFloat(minimumPrepaymentAmtS) > payEMIData?.total_amount}
                                                                            />
                                                                            <Typography className='calculated-amount-min-text'>{`Calculated for $${calculatedAmountS ?? 0}`}</Typography>
                                                                        </Box>
                                                                        <Button className='btn-rounded' disabled={(parseFloat(minimumPrepaymentAmtS) > 0 && (parseFloat(minimumPrepaymentAmtS) <= parseFloat(remainingPrincipalAmountS))) ? false : true} style={{ marginLeft: '10px', marginTop: '6px', height: '30px', width: '90px' }} onClick={() => getMinimumPrepayment(minimumPrepaymentAmtS, CAPITAL_TYPE_ID.SENIOR_DEBT)}>Calculate</Button>
                                                                    </Box>
                                                                    <Typography className='loan-prepayment error-text'>{(parseFloat(minimumPrepaymentAmtS) > parseFloat(remainingPrincipalAmountS)) && isCalculatedS ? `Value cannot be greater than $${formatNumber(parseFloat(remainingPrincipalAmountS))}` : ''}</Typography>

                                                                    <Divider sx={{ my: 1 }} />
                                                                    <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                                                                        <Typography style={rowTextStyles}>Principal Amount</Typography>
                                                                        <Typography style={rowTextStyles}>${parseFloat(payableAmountS - penaltyMinAmountS) > 0 ? formatNumber(parseFloat(payableAmountS - penaltyMinAmountS)) : 0}</Typography>
                                                                    </Stack>
                                                                    <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                                                                        <Typography style={rowTextStyles}>Penalty Amount</Typography>
                                                                        <Typography style={rowTextStyles}>${formatNumber(parseFloat(penaltyMinAmountS))}</Typography>
                                                                    </Stack>
                                                                </Box>
                                                            </Grid> : null
                                                        }
                                                        {payEMIData?.capital_type_id?.includes(CAPITAL_TYPE_ID.JUNIOR_DEBT) ?
                                                            <Grid item md={payEMIData?.capital_type_id?.length > 1 ? 6 : 12} sm={12}>
                                                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                                                    <Box className='minimum-prepayment'>
                                                                        <Box>
                                                                            <Typography className='font-16' style={{
                                                                                textAlign: "start",
                                                                                paddingBottom: "4px"
                                                                            }}>Junior Debt </Typography>
                                                                            <TextField
                                                                                className='minimum-prepayment-text'
                                                                                style={{ width: '200px' }}
                                                                                placeholder='Enter Amount'
                                                                                onChange={(e) => setMinimumPrepaymentAmt(Math.floor(parseFloat(e.target.value) || 0))}
                                                                                InputProps={{
                                                                                    inputComponent: NumericFormatCustomDollar,
                                                                                    style: {
                                                                                        color: 'white'
                                                                                    }
                                                                                }}
                                                                                value={minimumPrepaymentAmt}
                                                                                onKeyDown={(event) => {
                                                                                    if (event?.key === "-" || event?.key === "+" || event?.key === ".") {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                error={parseFloat(minimumPrepaymentAmt) > payEMIData?.total_amount}
                                                                            />
                                                                            <Typography className='calculated-amount-min-text'>{`Calculated for $${calculatedAmount ?? 0}`}</Typography>
                                                                        </Box>
                                                                        <Button className='btn-rounded' disabled={(parseFloat(minimumPrepaymentAmt) > 0 && (parseFloat(minimumPrepaymentAmt) <= parseFloat(remainingPrincipalAmount))) ? false : true} style={{ marginLeft: '10px', marginTop: '6px', height: '30px', width: '90px' }} onClick={() => getMinimumPrepayment(minimumPrepaymentAmt, CAPITAL_TYPE_ID.JUNIOR_DEBT)}>Calculate</Button>
                                                                    </Box>
                                                                    <Typography className='loan-prepayment error-text'>{(parseFloat(minimumPrepaymentAmt) > parseFloat(remainingPrincipalAmount)) && isCalculated ? `Value cannot be greater than $${formatNumber(parseFloat(remainingPrincipalAmount))}` : ''}</Typography>

                                                                    <Divider sx={{ my: 1 }} />
                                                                    <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                                                                        <Typography style={rowTextStyles}>Principal Amount</Typography>
                                                                        <Typography style={rowTextStyles}>${parseFloat(payableAmount - penaltyMinAmount) > 0 ? formatNumber(parseFloat(payableAmount - penaltyMinAmount)) : 0}</Typography>
                                                                    </Stack>
                                                                    <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                                                                        <Typography style={rowTextStyles}>Penalty Amount</Typography>
                                                                        <Typography style={rowTextStyles}>${formatNumber(parseFloat(penaltyMinAmount))}</Typography>
                                                                    </Stack>
                                                                </Box>
                                                            </Grid> : null
                                                        }
                                                    </Grid>
                                                    :
                                                    <>
                                                        <Box className='minimum-prepayment'>
                                                            <Typography>Enter Amount: </Typography>
                                                            <Box style={{ paddingTop: '16px' }}>
                                                                <TextField
                                                                    className='minimum-prepayment-text'
                                                                    style={{ width: '200px' }}
                                                                    placeholder='Enter Amount'
                                                                    onChange={(e) => setMinimumPrepaymentAmt(Math.floor(parseFloat(e.target.value) || 0))}
                                                                    InputProps={{
                                                                        inputComponent: NumericFormatCustomDollar,
                                                                        style: {
                                                                            color: 'white'
                                                                        }
                                                                    }}
                                                                    value={minimumPrepaymentAmt}
                                                                    onKeyDown={(event) => {
                                                                        if (event?.key === "-" || event?.key === "+" || event?.key === ".") {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    error={parseFloat(minimumPrepaymentAmt) > payEMIData?.total_amount}
                                                                />
                                                                <Typography className='calculated-amount-min-text'>{`Calculated for $${calculatedAmount ?? 0}`}</Typography>
                                                            </Box>
                                                            <Button className='btn-rounded' disabled={(parseFloat(minimumPrepaymentAmt) > 0 && (parseFloat(minimumPrepaymentAmt) <= parseFloat(remainingPrincipalAmount))) ? false : true} style={{ marginLeft: '10px', height: '30px', width: '90px' }} onClick={() => getMinimumPrepayment(minimumPrepaymentAmt)}>Calculate</Button>
                                                        </Box>
                                                        <Typography className='loan-prepayment error-text'>{(parseFloat(minimumPrepaymentAmt) > parseFloat(remainingPrincipalAmount)) && isCalculated ? `Value cannot be greater than $${formatNumber(parseFloat(remainingPrincipalAmount))}` : ''}</Typography>

                                                        <Divider sx={{ my: 1 }} />
                                                        <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                            <Typography style={rowTextStyles}>Principal Amount</Typography>
                                                            <Typography style={rowTextStyles}>${parseFloat(payableAmount - penaltyMinAmount) > 0 ? formatNumber(parseFloat(payableAmount - penaltyMinAmount)) : 0}</Typography>
                                                        </Stack>
                                                        <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                            <Typography style={rowTextStyles}>Penalty Amount</Typography>
                                                            <Typography style={rowTextStyles}>${formatNumber(parseFloat(penaltyMinAmount))}</Typography>
                                                        </Stack>
                                                    </>
                                            }
                                        </>
                                        :
                                        <>
                                            {(selectedOption === 'Pay EMI' && payableAmount > 0) ?
                                                <Grid container spacing={2} px={15}>
                                                    {/* Table Header */}
                                                    <Grid container item>
                                                        {/* <Grid item xs={4} sm={4} md={4}>
                                                            <Typography style={{ ...rowTextStyles, ...tableHeaderStyles }}>Offer</Typography>
                                                        </Grid> */}
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <Typography style={{ ...rowTextStyles, ...tableHeaderStyles, textAlign: 'start' }}>EMI Amount</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <Typography style={{ ...rowTextStyles, ...tableHeaderStyles, textAlign: 'center' }}>Penalty</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} md={4}>
                                                            <Typography style={{ ...rowTextStyles, ...tableHeaderStyles, textAlign: 'end' }}>Due Date</Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {/* Table Rows */}
                                                    {emiData?.map((row, index) => (
                                                        <Grid container item key={index}>
                                                            {/* <Grid item xs={4} sm={4} md={4}>
                                                                <Typography style={rowTextStyles}>{row.offer || 'N/A'}</Typography>
                                                            </Grid> */}
                                                            <Grid item xs={4} sm={4} md={4}>
                                                                <Typography style={{ ...rowTextStyles, textAlign: 'start' }}>
                                                                    ${row.emiAmount ? formatNumber(parseFloat(row.emiAmount)) : 0}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4}>
                                                                <Typography style={{ ...rowTextStyles, textAlign: 'center' }}>${formatNumber(row.penalty) || 0}</Typography>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4}>
                                                                <Typography style={{ ...rowTextStyles, textAlign: 'end' }}>{moment(adjustPaymentDate(row?.paymentDate), 'DD-MM-YYYY').format('ll')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                : null
                                            }

                                            {/* Divider */}
                                            {selectedOption === 'Pay Minimum Prepayment' ? <Divider sx={{ my: 2 }} /> : null}

                                            {selectedOption === 'Pay Prepayment' && payableAmount > 0 ?
                                                <>
                                                    <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                        <Typography style={rowTextStyles}>Principal Amount</Typography>
                                                        <Typography style={rowTextStyles}>${parseFloat(payableAmount - penaltyAmount) > 0 ? formatNumber(parseFloat(payableAmount - penaltyAmount)) : 0}</Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                        <Typography style={rowTextStyles}>Penalty Amount</Typography>
                                                        <Typography style={rowTextStyles}>${formatNumber(parseFloat(penaltyAmount))}</Typography>
                                                    </Stack>
                                                </>
                                                :
                                                <>
                                                    {
                                                        selectedOption === 'Distribute Exit Amount' && payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ? null :
                                                            <Grid container justifyContent="space-between" alignItems="center" px={15}>
                                                                <Grid item>
                                                                    <Typography style={rowTextStyles}>Amount (Amount + Penalty)</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={rowTextStyles}>${payableAmount > 0 ? formatNumber(parseFloat(payableAmount)) : 0}</Typography>
                                                                </Grid>
                                                            </Grid>

                                                    }
                                                </>
                                            }

                                            {selectedOption === 'Distribute Exit Amount' && payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ?
                                                <Box className='minimum-prepayment'>
                                                    <Typography>Enter Amount: </Typography>
                                                    <TextField
                                                        className='minimum-prepayment-text'
                                                        style={{ width: '200px' }}
                                                        placeholder='Enter Amount'
                                                        onChange={(e) => setDividendAmt(parseInt(e.target.value))}
                                                        InputProps={{
                                                            inputComponent: NumericFormatCustomDollar,
                                                            style: {
                                                                color: 'white'
                                                            }
                                                        }}
                                                        value={payDividendAmt}
                                                        onKeyDown={(event) => {
                                                            if (event?.key === "-" || event?.key === "+" || event?.key === ".") {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />

                                                </Box>
                                                : null
                                            }

                                        </>
                                    }

                                    <Box className='currency-align'>
                                        <Box className='investor-select-currency-type'>
                                            <Typography className='pay-using font-14'>
                                                Pay using
                                            </Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    aria-label="payment-method"
                                                    name="payment-method"
                                                    defaultValue="USDT"
                                                    row
                                                    className='currency-options'
                                                    value={selectedCurrency}
                                                    onChange={handleCurrencyChange}
                                                >
                                                    {/* <FormControlLabel
                                                        value="RACE"
                                                        control={<Radio />}
                                                        label="RACE Token"
                                                    /> */}
                                                    <FormControlLabel
                                                        value="USDT"
                                                        control={<Radio />}
                                                        label="USDT"
                                                    />
                                                    <FormControlLabel
                                                        value="USDC"
                                                        control={<Radio />}
                                                        label="USDC"
                                                    />
                                                    <FormControlLabel
                                                        value="DAI"
                                                        control={<Radio />}
                                                        label="DAI"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>

                                    <Box className='vote-btn-box' mt={2}>
                                        <Button className="wallet-button appr-btn" disabled={
                                            (selectedOption === 'Pay Minimum Prepayment' ?
                                                (parseFloat(calculatedAmount) <= parseFloat(remainingPrincipalAmount) || parseFloat(calculatedAmountS) <= parseFloat(remainingPrincipalAmountS)
                                                ) : true
                                            )
                                                && (payableAmount && parseFloat(payableAmount) > 0 || payableAmountS && parseFloat(payableAmountS) > 0)
                                                ? false : true}
                                            onClick={() => handleMakePayment()}> <Box className="btn-icon" ><Done />Pay ${parseFloat(payableAmount) + parseFloat(payableAmountS)}</Box></Button>
                                        <Button className="wallet-button rjct-btn" onClick={handleCloseMakePayment}><Box className="btn-icon" ><Close />Cancel</Box></Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        {
                            isLoading ?
                                <CommonBackdropLoader loading={isLoading} />
                                : null
                        }
                    </Box>
                </Modal >
                : null
            }

            {/* Transaction successful status modal */}
            {
                transactionModal.open ?
                    <Modal
                        open={transactionModal.open}
                        onClose={() => { setTransactionModal({ ...transactionModal, open: false }) }}
                        className="kyc-modal"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Box className="modal-body" sx={style}>
                            <Box className="headContent">
                                <Box className="logo">
                                    <Avatar
                                        alt="Logo"
                                        src={NewLogo}
                                        variant="square"
                                        sx={{ width: 39, height: 90, objectFit: "contain" }}
                                    />
                                </Box>
                                <Close className="close-btn" onClick={() => { setTransactionModal({ ...transactionModal, open: false }) }} />
                            </Box>
                            <Box className="modalContentDone" >
                                <Box className="main-content" >
                                    <Box className="DoneIcon"><Done className="doneIconSvg" /></Box>
                                    <Typography component="h1" className="headText">Payment Successful</Typography >
                                    <Typography component="p" className="sub-headText">{transactionModal?.title} {transactionModal?.message}</Typography >
                                    <Button onClick={() => { navigate('/user/transactions'); setTransactionModal({ ...transactionModal, open: false }); }} className="wallet-button">Return to Dashboard</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    : null
            }

            {
                openPayEMI
                    ? <TransactionProgressModal identity={'pay-emi'} confirmStake={confirmStake} propData={{ ...propData, feeTokenType: selectedCurrency }} handleModalClose={handleClosePayEMI} openTransactionModal={openPayEMI} />
                    : null
            }

            {
                openPrepayment
                    ? <TransactionProgressModal identity={'prepayment'} confirmStake={confirmStake} propData={{ ...propData, feeTokenType: selectedCurrency }} handleModalClose={handleClosePrepayment} openTransactionModal={openPrepayment} />
                    : null
            }

            {
                openMinimumPrepayment
                    ? <TransactionProgressModal identity={'minimum-prepayment'} confirmStake={confirmStake} propData={{ ...propData, feeTokenType: selectedCurrency }} handleModalClose={handleCloseMinimumPrepayment} openTransactionModal={openMinimumPrepayment} />
                    : null
            }
            {openPayExitDividend
                ? <PayExitTransactionProgressModal identity={'pay-exit-amount'} confirmStake={confirmStakeExitDividend} propData={{ ...propData, feeTokenType: selectedCurrency }} handleModalClose={handleCloseExit} openTransactionModal={openPayExitDividend} />
                : null
            }
        </>
    )

}