// storeCallData.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CATEGORY_TYPE_ID } from "../../constants";

/**
 * Call the storeCallData method for DIC to complete vote
 * @param {String} address user wallet address
 * @param {Number} proposalId asset's blockchain id
 * @param {Boolean} dicVoteType approve or reject
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function storeCallData(address, contract_address, contract_abi, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {

    try {
        let compilanceAddress = assetData?.data?.map((item) => item.compilance)
        let functionData = assetData?.data?.map((item) => item.result);
        let enableKyc = assetData?.enableKycOptionData;
        let tokenLockPeriod = assetData?.tokenLockPeriodData;
        let enableTransferApproval = assetData?.enableTransferApprovalData;

        const { request: storeCallData } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6803,
            functionName: "storeCallData",
            args: [
                assetData?.proposalId, // asset's blockchain id
                assetData?.assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ? assetData?.assetData?.assets_category?.blockchain_id : assetData?.assetData?.asset_sub_category_info?.blockchain_id, // category blockchain id
                assetData?.assetData?.investment_type?.blockchain_id, // investment blockchain id
                {
                    compilanceAddress,
                    functionData
                },
                {
                    enableKyc,
                    tokenLockPeriod,
                    enableTransferApproval
                }
            ]
        })
        const { hash } = await writeContract(storeCallData)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                handleActiveStep(2);
                showSnackbar("Transaction Successful", 'success')
                setTimeout(() => {
                    handleSuccess()
                    handleModalClose();
                    handleActiveStep(-1)

                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling store call data:', error);
        throw error; // propagate the error
    }
}

export { storeCallData };