// checkAllowance.js

import { readContract } from "@wagmi/core";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @param {String} spenderAddress spender contract address
 * @returns user wallet allowance
 */
async function checkAllowance(address, token_address, token_abi, spenderAddress) {
    try {

        // Now check for allowance Tokens
        const allowance = await readContract({
            address: token_address,
            abi: token_abi,
            account: address,
            functionName: "allowance",
            chainId: 6803,
            watch: true,
            args: [
                address,  // user wallet address
                spenderAddress  // spender contract address
            ]
        })
        return Number(allowance);
    } catch (error) {
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { checkAllowance };
