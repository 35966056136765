// viewListing.js

import { readContract } from "@wagmi/core";
import { INVESTMENT_TYPE_ID } from "../../constants";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @param {String} assetData asset data
 * @returns user wallet allowance
 */
async function viewListing(address, token_address, token_abi, assetData) {
    try {

        if (token_address && token_abi) {
            // Now check for allowance Tokens
            const listing = await readContract({
                address: token_address,
                abi: token_abi,
                account: address,
                functionName: assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL ? 'viewListings' : "viewListing",
                chainId: 6803,
                args: [
                    assetData?.listing_id
                ]
            })
            return listing;
        } else {
            return null
        }
    } catch (error) {
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { viewListing };
