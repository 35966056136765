// proposerStake.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";
import convertToTwoDecimal from "../Common/convertToTwoDecimal";

/**
 * pay proposer stake amount for creating a project
 * @param {String} address user wallet address
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function proposerStake(address, contract_address, contract_abi, token_address, user_id, asset_id, blockchain_category_id, blockchain_investment_type_id, projectAmt, stakeAmt, showSnackbar, handleActiveStep, handleModalClose, handleSuccess, assetData) {
    try {
        // set active step to 2
        handleActiveStep(2);
        let argsData = {}, functionName = '';
        if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // Asset investment type id (Asset investment id of blockchain)
                salePrice: convertToDecimal(parseFloat(projectAmt).toFixed(2)), // Amount of the project 
                depositAmount: convertToDecimal(parseFloat(assetData?.sale_deposit_amount).toFixed(2)), // Deposit amount
            }
            // if (assetData?.investment_duration) { // only from v2
            //     argsData.investmentTime = monthsInSecond(parseFloat(assetData?.investment_duration))
            // }
            functionName = "createSaleProposal"
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                saleAmount: convertToDecimal(parseFloat(projectAmt).toFixed(2)), // Amount of the project 
                noOfParcel: Number(assetData?.number_of_parcel), // Total number of parcel
                minInvestment: convertToDecimal(parseFloat(assetData?.minimun_investment_per_parcel).toFixed(2)),
                claimedPercentage: convertToTwoDecimal(0), // Withdrawal Threshold
            }

            functionName = "createParcelProposal"
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                minAmount: convertToDecimal(assetData?.loan_minimum_investment),
                maxAmount: convertToDecimal(assetData?.loan_amount),
                minInvestment: convertToDecimal(assetData?.loan_minimum_investment),
                minPercentage: Number(1 * 100),
                maxPercentage: Number(Math.ceil(parseFloat(assetData?.loan_roi) * 100)),
                minDuration: Number(assetData?.loan_iop + 1),
                maxDuration: Number(1 * assetData?.loan_duration_month),
                debtToken: {
                    tokenName: 'Debt Token',
                    tokenSymbol: 'D',
                    interestOnlyPeriod: Number(assetData?.loan_iop),
                    paymentDate: Number(assetData?.loan_repay_day),
                    claimedPercentage: Number(0 * 100),
                    thresholdDays: daysInSecond(0),
                    emiBufferDays: 0,
                    offerExpiryTime: daysInSecond(3)
                }
            }
            functionName = "createLoanProposal"
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            let senior = assetData?.capitalTypeDetails?.filter((items) => Object.keys(items)[0] === 'Senior Debt');
            let junior = assetData?.capitalTypeDetails?.filter((items) => Object.keys(items)[0] === 'Junior Debt');
            let equity = assetData?.capitalTypeDetails?.filter((items) => Object.keys(items)[0] === 'Equity');

            let debtToken = [], equityToken = {}, totalDebt = 0, totalEquity = 0, equityPercentage = 0;
            if (senior?.length && junior?.length) {
                let seniorObj = senior[0]['Senior Debt']
                let juniorObj = junior[0]['Junior Debt']

                totalDebt = parseFloat(seniorObj?.total_raise) + parseFloat(juniorObj?.total_raise)
                debtToken = [
                    {
                        tokenName: seniorObj?.tokenName ? seniorObj?.tokenName : "Senior Debt",
                        tokenSymbol: seniorObj?.tokenSymbol ? seniorObj?.tokenSymbol : 'S',
                        totalRaise: convertToDecimal(seniorObj?.total_raise),
                        minInvestment: convertToDecimal(seniorObj?.minimum_investment),
                        percentage: Math.ceil(parseFloat(seniorObj?.rate_of_return) * 100),
                        duration: seniorObj?.senior_duration * 12,
                        interestOnlyPeriod: seniorObj?.iop_senior,
                        amortizationPeriod: (seniorObj?.amortization_duration) * 12,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(0 * 100),
                    },
                    {
                        tokenName: juniorObj?.tokenName ? juniorObj?.tokenName : "Junior Debt",
                        tokenSymbol: juniorObj?.tokenSymbol ? juniorObj?.tokenSymbol : 'J',
                        totalRaise: convertToDecimal(juniorObj?.total_raise),
                        minInvestment: convertToDecimal(juniorObj?.minimum_investment),
                        percentage: (Math.ceil(parseFloat(juniorObj?.rate_of_return) * 100)),
                        duration: juniorObj?.junior_duration * 12,
                        interestOnlyPeriod: juniorObj?.iop_junior,
                        amortizationPeriod: (juniorObj?.amortization_duration) * 12,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(0 * 100),
                    }
                ]
            } else if (senior?.length) {
                let seniorObj = senior[0]['Senior Debt']

                totalDebt = parseFloat(seniorObj?.total_raise)
                debtToken = [
                    {
                        tokenName: seniorObj?.tokenName ? seniorObj?.tokenName : "Senior Debt",
                        tokenSymbol: seniorObj?.tokenSymbol ? seniorObj?.tokenSymbol : 'S',
                        totalRaise: convertToDecimal(seniorObj?.total_raise),
                        minInvestment: convertToDecimal(seniorObj?.minimum_investment),
                        percentage: (Math.ceil(parseFloat(seniorObj?.rate_of_return) * 100)),
                        duration: seniorObj?.senior_duration * 12,
                        interestOnlyPeriod: seniorObj?.iop_senior,
                        amortizationPeriod: (seniorObj?.amortization_duration) * 12,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(0 * 100),
                    }
                ]
            } else if (junior?.length) {
                let juniorObj = junior[0]['Junior Debt']

                totalDebt = parseFloat(juniorObj?.total_raise)
                debtToken = [
                    {
                        tokenName: "Senior Debt Token",
                        tokenSymbol: 'SDT',
                        totalRaise: 0,
                        minInvestment: 0,
                        percentage: 0,
                        duration: 0,
                        interestOnlyPeriod: 0,
                        amortizationPeriod: 0,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(0 * 100),
                    },
                    {
                        tokenName: juniorObj?.tokenName ? juniorObj?.tokenName : "Junior Debt",
                        tokenSymbol: juniorObj?.tokenSymbol ? juniorObj?.tokenSymbol : 'J',
                        totalRaise: convertToDecimal(juniorObj?.total_raise),
                        minInvestment: convertToDecimal(juniorObj?.minimum_investment),
                        percentage: (Math.ceil(parseFloat(juniorObj?.rate_of_return) * 100)),
                        duration: juniorObj?.junior_duration * 12,
                        interestOnlyPeriod: juniorObj?.iop_junior,
                        amortizationPeriod: (juniorObj?.amortization_duration) * 12,
                        titledCompanyAddress: assetData?.managementAddress,
                        claimedPercentage: Number(0 * 100),
                    }
                ]
            }

            if (equity?.length) {
                let equityObj = equity[0]['Equity']

                totalEquity = parseFloat(equityObj.total_raise)
                equityToken =
                {
                    tokenName: equityObj?.tokenName ? equityObj?.tokenName : "Limited Partner",
                    tokenSymbol: equityObj?.tokenSymbol ? equityObj?.tokenSymbol : 'LP',
                    totalRaise: convertToDecimal(equityObj?.total_raise),
                    minInvestment: convertToDecimal(equityObj?.minimum_investment),
                    equityPercentage: Number(Math.ceil(parseFloat(equityObj?.equity) * 100)),
                    cashOnYield: 24,
                    titledCompanyAddress: assetData?.managementAddress,
                    claimedPercentage: Number(0 * 100),
                }
                equityPercentage = (Math.ceil(parseFloat(equityObj?.equity) * 100));
            } else {
                equityToken =
                {
                    tokenName: "Limited Partner",
                    tokenSymbol: 'LP',
                    totalRaise: 0,
                    minInvestment: 0,
                    equityPercentage: 0,
                    cashOnYield: 0,
                    titledCompanyAddress: assetData?.managementAddress,
                    claimedPercentage: Number(0 * 100),
                }
            }

            let hurdleParam = {
                isHurdleRate: false,
                // isResetHurdle: false,
                minIRRPercent: [],
                maxIRRPercent: [],
                partners: [],
                carriedPercentage: 0,
                gpPercentage: 0,
            };
            let debtDetail = {
                thresholdDays: daysInSecond(0),
                emiBufferDays: 0,
                paymentDate: Number(assetData?.loan_repay_day),
            }
            if (assetData?.is_hurdle_rate && equity?.length) {
                let irrData = JSON.parse(assetData?.spv_rate_of_return);
                let partnersData = JSON.parse(assetData?.spv_partners);
                const minIRRPercent = irrData?.length > 0 ? irrData?.map(item => Math.ceil(parseFloat(item.min) * 100)) : [];
                const maxIRRPercent = irrData?.length > 0 ? irrData?.map(item => Math.ceil(parseFloat(item.max) * 100)) : [];
                const partners = partnersData?.length > 0 ? partnersData?.map(item => [Math.ceil(parseFloat(item.lp)) * 100, Math.ceil((100 - parseFloat(item.lp)) * 100)]) : [];
                hurdleParam = {
                    isHurdleRate: Boolean(assetData.is_hurdle_rate),
                    isResetHurdle: false,
                    minIRRPercent: minIRRPercent,
                    maxIRRPercent: maxIRRPercent,
                    partners: partners,
                    carriedPercentage: parseFloat(assetData?.carried_interest) ? Math.ceil(parseFloat(assetData?.carried_interest) * 100) : 0,
                    gpPercentage: parseFloat(assetData?.spv_lp_percentage) ? Math.ceil(parseFloat(assetData?.spv_lp_percentage) * 100) : 0,
                };
            }

            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                totalCapital: convertToDecimal(assetData?.fractionalize_project_size), // project size 
                totalRaise: convertToDecimal(assetData?.fractionalize_total_price), // total raise amount
                totalDebtAmt: convertToDecimal(totalDebt),
                totalEquityAmt: convertToDecimal(totalEquity),
                proposerPercentage: Number(10000 - equityPercentage),
                equityPercentage: Number(equityPercentage),
                projectDuration: Number(assetData?.fractionalize_duration_of_project * 12), // duration of loan amount,
                debtToken,
                debtDetail,
                equityToken,
                hurdleParam
            }

            functionName = "createSPVProposal"
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: assetData?.blockchain_asset_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                tokenName: assetData?.tokenName,
                tokenSymbol: assetData?.tokenSymbol,
                fundSize: convertToDecimal(assetData.fractionalize_total_price), // totalRaise
                initialDuration: Number(yearsInSecond(assetData?.fractionalize_duration_of_project)), // 
                investmentDuration: Number(daysInSecond(assetData?.investment_duration)), // 
                managementFees: Number(Math.ceil(assetData?.management_fees * 100)), // 
                managementAddress: assetData?.managementAddress, // for now it is proposer address
                // perYearFees: Number(Math.ceil((assetData?.management_fees / assetData?.fractionalize_duration_of_project) * 100)), // 
                carriedInterest: Number(Math.ceil(assetData?.carried_interest * 100)), // 
                minInvestment: convertToDecimal(assetData.fractionalize_minimum_investment), // totalRaise
                claimedPercentage: Number(0 * 100),
                tokenLockPeriod: Number(0),
            }
            functionName = "createFundProposal"
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            let classA = '', classB = '';

            let HurdleRate = {
                isHurdleRate: true,
                prefferdPercentage: [],
                LPPercentage: [],
                GPPercentage: [],
            };

            assetData?.shared_data?.forEach((capitals, index) => {
                if (index == 0) {
                    HurdleRate?.prefferdPercentage.push(Number(Math.ceil(parseFloat(capitals?.hurdle_rate * 100))));
                    HurdleRate?.LPPercentage.push(parseFloat(capitals?.lp_percentage) * 100);
                    HurdleRate?.GPPercentage.push((100 - parseFloat(capitals?.lp_percentage)) * 100);
                    classA = {
                        tokenName: "Class A",
                        tokenSymbol: 'A',
                        totalRaise: convertToDecimal(capitals?.total_raise),
                        minInvestment: convertToDecimal(capitals?.minimum_investment),
                    }
                } else if (index == 1) {
                    HurdleRate?.prefferdPercentage.push(Number(Math.ceil(parseFloat(capitals?.hurdle_rate * 100))));
                    HurdleRate?.LPPercentage.push(parseFloat(capitals?.lp_percentage) * 100);
                    HurdleRate?.GPPercentage.push((100 - parseFloat(capitals?.lp_percentage)) * 100);
                    classB = {
                        tokenName: "Class B",
                        tokenSymbol: 'B',
                        totalRaise: convertToDecimal(capitals?.total_raise),
                        minInvestment: convertToDecimal(capitals?.minimum_investment),
                    }
                }
            })
            let equityParam = [];
            if (classA && classB) {
                equityParam = [classA, classB]
            } else if (classA) {
                equityParam = [classA]
            } else if (classA) {
                equityParam = [classB]
            }

            argsData = {
                projectId: asset_id, // Asset id / Project id (Backend)
                assetId: blockchain_category_id, // Asset category id (Asset category id of blockchain)
                investmentId: blockchain_investment_type_id, // asset investment type id (Asset investment id of blockchain)
                totalRaise: convertToDecimal(assetData.fractionalize_total_price), // totalRaise
                duration: Number(yearsInSecond(assetData?.fractionalize_duration_of_project)), // 
                claimedPercentage: Number(0 * 100),
                tokenLockPeriod: Number(0),
                fundType: equityParam, // classTypeParam
                hurdleParam: HurdleRate
            }
            functionName = "createFundProposal"
        }

        const { request: createProposal } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6803,
            functionName: functionName,
            args: [
                argsData,
                token_address, // _feeToken
                getCurrencyTypeDecimal(assetData?.feeTokenType, stakeAmt), // Stake amount
            ]
        })
        const { hash } = await writeContract(createProposal)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, stakeAmt, asset_id, user_id)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in creating proposal:', error);
        throw error; // propagate the error
    }
}

export { proposerStake };

const second = 86400;

const yearsInSecond = (years) => {
    return parseInt(years) * second * 365
}
const monthsInSecond = (month) => {
    return parseInt(month) * second * 30
}
const daysInSecond = (day) => {
    return parseInt(day) * second
}