// calculateRemainingAmount.js

import { readContract } from "@wagmi/core";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Fetch user wallet balance
 * @param {Object} payEMIData asset details 
 * @returns emi amount for current date
 */
async function calculateRemainingAmount(contract_address, contract_abi, payEMIData) {
    try {
        if (contract_address && contract_abi) {
            let args = [
                payEMIData.listing_id
            ]
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                if (payEMIData?.isPayExit) {
                    const getPrepayment = await readContract({
                        address: contract_address,
                        abi: contract_abi,
                        functionName: "calculateRemainingAmt",
                        chainId: 6803,
                        watch: true,
                        args: [
                            payEMIData.listing_id,
                            payEMIData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1,
                            getCurrencyTypeDecimal('DAI', payEMIData?.amount)
                        ]
                    });
                    return getPrepayment
                } else {
                    const getPrepayment = await readContract({
                        address: contract_address,
                        abi: contract_abi,
                        functionName: "calculatePrepaymentAmount",
                        chainId: 6803,
                        watch: true,
                        args: [
                            payEMIData.listing_id,
                            payEMIData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1
                        ]
                    });
                    return getPrepayment
                }
            } else {
                const getPrepayment = await readContract({
                    address: contract_address,
                    abi: contract_abi,
                    functionName: "calculateRemainingAmt",
                    chainId: 6803,
                    watch: true,
                    args: args
                });
                return getPrepayment
            }
        }
    } catch (error) {
        console.error('Error fetching prepayment amount:', error);
        throw error; // propagate the error
    }
}

export { calculateRemainingAmount };
